/*========= All form Elements Style =========*/
.css-9q8wwa-MuiFormLabel-root-MuiInputLabel-root{
  color: #000000;
}
.css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input,
.css-156w1hu-MuiInputBase-root-MuiOutlinedInput-root,
.css-xa8dnj-MuiInputBase-root-MuiOutlinedInput-root,
.css-1xrdw5t-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  border-radius: 5px;
}
// .MuiInputBase-formControl .css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input,
// .MuiInputBase-formControl.css-156w1hu-MuiInputBase-root-MuiOutlinedInput-root,
// .MuiInputBase-formControl.css-xa8dnj-MuiInputBase-root-MuiOutlinedInput-root,
// .MuiInputBase-formControl.css-1xrdw5t-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
//   background: #f2d8b3;
// }
.Mui-focused .css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input,
.Mui-focused.css-156w1hu-MuiInputBase-root-MuiOutlinedInput-root,
.Mui-focused.css-xa8dnj-MuiInputBase-root-MuiOutlinedInput-root,
.Mui-focused.css-1xrdw5t-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  background: #ffffff;
}
.css-ilodt5-MuiInputBase-input-MuiOutlinedInput-input,
.css-1orpw9n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  background: inherit;
}
.input-group-prepend {
  @include border(1px solid, $input-border-color, top bottom left);
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  button {
    color: inherit;
  }
}
.form-control{
  box-shadow: inherit;
}
.input-lg {
  height: $input-lg-height;
  padding: 0.375rem 0.7rem; // 6px 30px;
}
.input-lg-icon {
  height: $input-lg-height;
  padding: 0.375rem 2.7rem; // 6px 30px;
}
.app-selectbox {
  border: 1px solid $gray-300;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: $input-lg-height;
  box-shadow: $block-shadow;
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: $caret-width * -0.3;
    right: 10px;
    width: 0;
    height: 0;
    border: $caret-width solid transparent;
    border-top-color: $gray-900;
  }
  select {
    padding: 0.3125rem 0.5rem; //5px 8px;
    box-shadow: none;
    background-color: $block-bg;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-transform: capitalize;
    height: $input-lg-height !important;
    &:focus {
      outline: none;
    }
  }
}
.app-selectbox-sm {
  @extend .app-selectbox;
  height: $input-height;
  select {
    height: $input-height !important;
  }
}
#reactselect-single,#reactselect-chip {
	.css-bg1rzq-control,.css-1szy77t-control{
		border-color:transparent !important;
		box-shadow: none !important; 
	}
}
.css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall,
.css-ilodt5-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall,
.css-1orpw9n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
  padding: 7px 10px;
}
.css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
  padding: 7px 10px;
}
.css-ozlkxp-MuiFormLabel-root-MuiInputLabel-root{
  line-height: 1.2;
  transform: translate(14px, 9px) scale(1);
}
.form-check-input{margin-top: 0rem;}
.managedate{
  color: #9e9e9e;
  border-color: #9e9e9e;
}
.mngdevider{
  width: 100%;
  position: relative;
  padding: 10px 10px;
  margin-bottom: 12px;
  border: 1px solid #9e9e9e;
}
.mngtitle{
  top: -10px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
  position: absolute;
  background-color: #fff;
}
.mngradio{
  top: -10px;
  left: 150px;
  font-size: 14px;
  padding: 0 5px;
  position: absolute;
  background-color: #fff;
}
.css-19kzrtu{
  padding: 15px;
}
.bdrdevider{
  line-height: 0;
  margin-top: 30px;
  margin-bottom: 35px;
  text-align: center;
  position: relative;
  border-bottom: 2px solid #888888;
  span{
    top: 14px;
    line-height: 1;
    font-size: 25px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    text-transform: uppercase;
    background-color: white;
  }
}
.row{
  margin-right: -5px;
  margin-left: -5px;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1{
  padding-right: 5px;
  padding-left: 5px;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
}
.css-1vptd2f-MuiButtonBase-root-MuiCheckbox-root{
  padding: 0;
}
.css-j204z7-MuiFormControlLabel-root{
  margin: 0;
}
.css-14qh0u1-MuiOutlinedInput-notchedOutline{
  border-radius: 4px;
}
.css-yf8vq0-MuiSelect-nativeInput,
.css-9q8wwa-MuiFormLabel-root-MuiInputLabel-root,
.css-1xrdw5t-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-dtknuo-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall{
  font-size: 0.9rem;
}
.css-9q8wwa-MuiFormLabel-root-MuiInputLabel-root{
  line-height: 1.1em;
}
.custommodalheader.modal-header{
  align-items: center;
  .modal-title{
    width: 60%;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 992px){
  .modal-xl {
    max-width: 1100px;
  }
}