/*========= All New Custom Style =========*/
.css-1vw870h,.css-1junmqd,.css-1bah7hx{
    padding: 7px 14px !important;
}
.css-1dhsxmb{
    color: #212121 !important;
}
// .css-1vw870h,.css-1hh0np,.css-1bah7hx,.css-qcffvi,.css-1junmqd,.css-ompl7e,.css-1nvb8y6{
//     background: #f2d8b3 !important;
// }
.css-1vw870h,.css-1hh0np,.css-1bah7hx,.css-qcffvi,.css-1junmqd,.css-ompl7e,.css-ncxurb,.css-1nvb8y6{
    border-radius: 5px !important;
}
// .css-1teaio1{
//     width: 16% !important;
//     left: 0 !important;
// }
.css-1teaio1,.css-7kc8d{
    z-index: 10 !important;
}
.css-1jaw3da{
    margin: 0 !important;
    margin-bottom: -8px !important;
}
.css-1dhsxmb{
    font-size: 0.8rem;
}
.css-1brlyt0{
    padding: 2px 10px;
}
.css-1xjgkjx{
    padding: 5px 10px;
}
.css-aym2nz .MuiDataGrid-row,.css-aym2nz .MuiDataGrid-cell{
    max-height: 34px !important;
    min-height: 34px !important;
}